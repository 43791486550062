<template>
  <div class="container-pin-code">
    <input v-model="digits" type="number" pattern="\d*" />
    <div class="digit" v-for="(digit, index) in digits" :key="index">
      <span>{{ digit }}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: "Pincode",
  props: ["phoneToken", "onFulfilled"],
  data() {
    return {
      maxLength: 6,
    };
  },
  mounted() {
    this.onKeyDown = (event) => {
      if (event.key === "Backspace") {
        this.computedPhoneToken = this.computedPhoneToken.slice(0, -1);
        return;
      }

      if (this.computedPhoneToken.length >= this.maxLength) {
        return;
      }

      const digits = ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9"];
      if (digits.indexOf(event.key) !== -1) {
        this.computedPhoneToken += event.key;
        return;
      }

      const boomers = ["à", "&", "é", '"', "'", "(", "§", "è", "!", "ç"];
      const index = boomers.indexOf(event.key);
      if (index !== -1) {
        this.computedPhoneToken += `${index}`;
      }
    };

    document.addEventListener("keydown", this.onKeyDown);
  },
  unmounted() {
    document.removeEventListener("keydown", this.onKeyDown);
  },
  computed: {
    computedPhoneToken: {
      get: function () {
        return this.phoneToken || "";
      },
      set: function (newOptions) {
        this.$emit("update:phoneToken", newOptions);
        if (newOptions.length === 6) {
          this?.onFulfilled(newOptions);
        }
      },
    },
    digits: {
      get: function () {
        const phoneToken = `${this.computedPhoneToken || ""}`;
        const len = phoneToken.length;

        return phoneToken + " ".repeat(this.maxLength - len);
      },
    },
  },
};
</script>

<style scoped>
.container-pin-code {
  display: flex;
  gap: 8px;
  position: relative;
  justify-content: center;
}

.container-pin-code input {
  position: absolute;
  height: 100%;
  width: 100%;
  background: transparent;
  border: none;
  color: transparent;
  opacity: 0;
}

.container-pin-code input:focus {
  outline: none;
}

.container-pin-code input::-webkit-outer-spin-button,
.container-pin-code input::-webkit-inner-spin-button {
  /* display: none; <- Crashes Chrome on hover */
  -webkit-appearance: none;
  margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

.container-pin-code input[type="number"] {
  -moz-appearance: textfield; /* Firefox */
}

.container-pin-code input::selection {
  background: transparent;
}

.container-pin-code input::-moz-selection {
  background: transparent;
}

.container-pin-code .digit {
  width: 60px;
  height: 100px;
  background: rgba(207, 210, 212, 0.3);
  border: 1px solid #cfd2d4;
  border-radius: 5px;
  color: var(--kermony-office-bleu-1);
  font-family: "Weissenhof Grotesk";
  font-style: normal;
  font-weight: 400;
  font-size: 25px;
  line-height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}
</style>
