<template>
  <div class="layout-login">
    <input
      id="autofocus-pincode"
      style="opacity: 0; position: absolute"
      type="number"
      pattern="\d*"
    />
    <div class="card-login">
      <Button
        v-if="!(showPhoneAuth === false && showForgotPassword === false)"
        @click="resetState"
        style="position: absolute; left: 20px"
        icon-left="chevron-left"
        theme="tertiary"
        size="medium"
      ></Button>
      <LogoWhite />

      <div class="login-signin" v-if="!this.showForgotPassword">
        <div>
          <div class="login-header" v-if="!this.showPhoneAuth">
            <Heading5>Connexion à Ma Sentinelle</Heading5>
            <Subtitle1 color="gray-2">
              Veuillez rentrer vos identifiants
            </Subtitle1>
          </div>
          <div class="login-header" v-else>
            <Heading5>Double authentification</Heading5>
            <Subtitle1 color="gray-2"
              >Veuillez renseigner le code à 6 chiffres reçu par SMS</Subtitle1
            >
          </div>

          <div v-if="this.errorMessage">
            <div class="alert-icon">
              <i class="flaticon-warning"></i>
            </div>
            <div class="alert-text text-center" style="margin-top: 10px">
              {{ this.errorMessage }}
            </div>
          </div>
        </div>
        <form @submit.prevent="handleSubmit" v-if="!this.showPhoneAuth">
          <div class="container-field">
            <Subtitle1 color="blue-1">Email</Subtitle1>
            <input
              type="text"
              placeholder="Email"
              name="username"
              autocomplete="off"
              v-model="auth.email"
            />
          </div>
          <div class="container-field">
            <Subtitle1 color="blue-1">Mot de passe</Subtitle1>
            <input
              type="password"
              placeholder="Mot de passe"
              name="password"
              v-model="auth.password"
            />
          </div>
          <div class="text-right">
            <a @click="toogleForgotPassword()" href="javascript:;" class="link">
              Mot de passe oublié ?
            </a>
          </div>
          <div style="margin-top: 16px; justify-content: center; display: flex">
            <Button
              v-on:click="openKeyboardPincode"
              theme="primary"
              size="large"
              :shadow="true"
              >CONNEXION</Button
            >
          </div>
        </form>
        <!-- SMS VALIDATION -->
        <form @submit.prevent="handlePhoneValidation" v-if="this.showPhoneAuth">
          <Pincode
            v-model:phoneToken="auth.phoneToken"
            :onFulfilled="handlePhoneValidation"
          />

          <div style="margin-top: 16px; justify-content: center; display: flex">
            <Button theme="primary" size="large" :shadow="true"
              >CONFIRMER</Button
            >
          </div>
        </form>
        <div class="no-account">
          <Subtitle1 color="gray-2" v-if="!this.showPhoneAuth"
            >Vous n’avez pas de compte ?
          </Subtitle1>
          <Subtitle1 color="gray-2" v-else
            >Vous rencontrez un problème ?
          </Subtitle1>

          <a class="link" href="javascript:masentinelle@kermomy-office.com"
            >Contactez nous!</a
          >
        </div>
      </div>

      <!-- FORGOT -->
      <div
        class="login-forgot"
        v-if="this.showForgotPassword && !showFoggotPasswordSuccess"
      >
        <div class="login-header" v-if="!this.showPhoneAuth">
          <Heading5>Mot de passe oublié</Heading5>
          <Subtitle1 color="gray-2">
            Renseignez votre email pour recevoir un lien de réinitialisation de
            votre mot de passe.
          </Subtitle1>
        </div>

        <form
          class="form"
          id="kt_login_forgot_form"
          @submit.prevent="handleReset"
        >
          <div class="container-field">
            <Subtitle1 color="blue-1">Email</Subtitle1>
            <input
              v-model="auth.email"
              class="form-control form-control-solid h-auto py-4 px-8"
              type="text"
              placeholder="Email"
              name="email"
              autocomplete="off"
            />
          </div>
          <div style="margin-top: 16px; justify-content: center; display: flex">
            <Button theme="primary" size="large" :shadow="true">
              RECEVOIR UN LIEN
            </Button>
          </div>
        </form>
        <div class="no-account">
          <Subtitle1 color="gray-2">Vous avez oublié votre email ?</Subtitle1>
          <a class="link" href="mailto:contact@kermony-office.com"
            >Contactez nous!</a
          >
        </div>
      </div>
      <!--end::Login forgot password form-->

      <div class="login-forgot-success" v-if="showFoggotPasswordSuccess">
        <div class="login-header">
          <Heading5>Bien reçu !</Heading5>
          <Subtitle1 color="gray-2">
            Nous vous avons envoyé un email contenant un lien de
            réinitialisation de mot de passe. Cliquez sur ce lien pour choisir
            un nouveau de mot de passe sécurisé.
          </Subtitle1>
        </div>
        <div style="margin-top: 16px; justify-content: center; display: flex">
          <router-link :to="{ name: 'login' }">
            <Button
              @click="resetState"
              theme="tertiary"
              size="large"
              :shadow="true"
              >RETOUR A LA PAGE DE CONNEXION
            </Button>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import "@/assets/styles/login.min.css";

import authApi from "@/api/auth";
import LogoWhite from "@/views/Auth/Login/LogoWhite";
import Heading5 from "@/components/Texts/Heading5";
import Subtitle1 from "@/components/Texts/Subtitle1";
import Button from "@/components/Buttons/Button";
import Pincode from "@/views/Auth/Login/Pincode";

export default {
  name: "Login",
  components: { Pincode, Button, Subtitle1, Heading5, LogoWhite },
  data() {
    return {
      auth: {},
      submitted: false,
      errorMessage: null,
      showPhoneAuth: false,
      showForgotPassword: false,
      showFoggotPasswordSuccess: false,
    };
  },
  computed: {
    digits: {
      get: function () {
        const phoneToken = `${this.auth.phoneToken || ""}`;
        const len = phoneToken.length;

        return phoneToken + " ".repeat(6 - len);
      },
    },
  },

  mounted() {
    // Auto fill email
    if (this.auth && this.$route.query.email)
      this.auth.email = this.$route.query.email;

    document.querySelector(".grecaptcha-badge").style.visibility = "visible";
  },

  unmounted() {
    document.querySelector(".grecaptcha-badge").style.visibility = "hidden";
  },

  methods: {
    openKeyboardPincode() {
      const pincode = document.getElementById("autofocus-pincode");
      pincode.focus();
    },

    resetState() {
      this.auth = {};
      this.submitted = false;
      this.errorMessage = null;
      this.showPhoneAuth = false;
      this.showForgotPassword = false;
      this.showFoggotPasswordSuccess = false;
    },

    toogleForgotPassword() {
      this.showForgotPassword = !this.showForgotPassword;
    },

    resetPhoneAuth() {
      delete this.auth.phoneToken;

      this.errorMessage = null;
      this.showPhoneAuth = false;
    },

    handleSubmit() {
      // Reset errors
      this.errorMessage = null;

      // Mark form as submitted
      this.submitted = true;

      const auth = this.auth;
      // eslint-disable-next-line no-undef
      grecaptcha.ready(() => {
        // eslint-disable-next-line no-undef
        grecaptcha
          .execute(process.env.VUE_APP_GOOGLE_RECAPTCHA_API_KEY, {
            action: "submit",
          })
          // eslint-disable-next-line no-undef,no-unused-vars
          .then((token) => {
            authApi
              .login({
                email: auth.email,
                password: auth.password,
                recaptchaToken: token,
              })
              .then(() => {
                // TODO SHOW PHONE NUMBER
                this.showPhoneAuth = true;
              })
              .catch((error) => {
                if (error.response) {
                  console.log(
                    "[handleSubmit]",
                    "Error from API:",
                    error.response.data.message
                  );
                  this.errorMessage = error.response.data.message;
                }
              });
          });
      });
    },

    handlePhoneValidation() {
      // Reset errors
      this.errorMessage = null;

      // Mark form as submitted
      this.submitted = true;

      authApi
        .verifyLogin({
          email: this.auth.email,
          phoneToken: `${this.auth.phoneToken}`,
        })
        .then((response) => {
          this.$store
            .dispatch("validateLogin", response)
            .then(() => {
              this.$router.push("/");
            })
            .catch(() => {
              this.errorMessage =
                "Impossible de vous connecter, merci de contacter le support.";
            });
        })
        .catch((error) => {
          if (error.response) {
            console.log(
              "[handlePhoneValidation]",
              "Error from API:",
              error.response.data.message
            );
            this.errorMessage = error.response.data.message;
          }
        });
    },

    handleReset() {
      // Reset errors
      this.errorMessage = null;

      if (!this.auth || !this.auth.email)
        return alert("Veuillez saisir une adresse email.");

      // eslint-disable-next-line no-undef
      grecaptcha.ready(() => {
        // eslint-disable-next-line no-undef
        grecaptcha
          .execute(process.env.VUE_APP_GOOGLE_RECAPTCHA_API_KEY, {
            action: "submit",
          })
          // eslint-disable-next-line no-undef,no-unused-vars
          .then((token) => {
            authApi
              .resetPassword({ email: this.auth.email, recaptchaToken: token })
              .then(() => {
                this.showFoggotPasswordSuccess = true;
              })
              .catch((error) => {
                if (error.response) {
                  console.log(
                    "[resetPassword]",
                    "Error from API:",
                    error.response.data.message
                  );
                  this.errorMessage = error.response.data.message;
                }
              });
          });
      });
    },
  },
};
</script>

<style scoped>
.layout-login {
  height: 100%;
  width: 100%;
  background: url(/filigrane.png) #fbf9fa;
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.layout-login .card-login {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 32px;
  gap: 24px;

  position: absolute;
  width: 472px;
  /*height: 500px;*/
  /*min-height: 400px;*/

  /* Kermony/Blanc */

  background: #ffffff;
  /* Card shadow */

  box-shadow: 0px 2px 12px rgba(33, 36, 78, 0.1);
  border-radius: 5px;
  max-width: calc(100% - 32px);
  margin: 16px;
}

.layout-login .login-header {
  display: flex;
  gap: 8px;
  flex-direction: column;
  text-align: center;
}

.layout-login .login-signin,
.layout-login .login-forgot,
.layout-login .login-forgot-success {
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 100%;
}

.layout-login form {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.layout-login .no-account {
  display: flex;
  justify-content: center;
  gap: 8px;
}

.container-field {
  display: flex;
  flex-direction: column;
  gap: 4px;
}
.container-field input {
  background: #ffffff;
  border: 1px solid #cfd2d4;
  border-radius: 5px;
  padding: 8px 0px 8px 8px;
  font-family: "Weissenhof Grotesk";
  font-style: normal;
  font-weight: 280;
  font-size: 17px;
  line-height: 22px;
  letter-spacing: 0.5px;
  color: #21244e;
}

.container-field input:focus {
  outline: none;
}

.layout-login .link {
  color: var(--kermony-office-rose);
  font-family: "Weissenhof Grotesk";
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 19px;
  text-align: center;
  letter-spacing: 1.25px;
  text-transform: uppercase;
}

.text-center {
  text-align: center;
}
</style>
