import HTTP from "@/axios";

export default {
  login(data) {
    return new Promise((resolve, reject) => {
      HTTP.post("auth/login", data)
        .then((response) => {
          if (response) return resolve(response.data);

          return resolve();
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  revoke(data) {
    return new Promise((resolve, reject) => {
      HTTP.post("auth/revoke", data)
        .then((response) => {
          if (response) return resolve(response.data);

          return resolve();
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  verifyLogin(data) {
    return new Promise((resolve, reject) => {
      HTTP.post("auth/verify-login", data)
        .then((response) => {
          if (response) return resolve(response.data);

          return resolve();
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  resetPassword(data) {
    return new Promise((resolve, reject) => {
      HTTP.post("auth/reset-password", data)
        .then((response) => {
          if (response) return resolve(response.data);

          return resolve();
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  refreshToken(data) {
    return new Promise((resolve, reject) => {
      HTTP.post("auth/refresh-token", data)
        .then((response) => {
          if (response) return resolve(response.data);

          return resolve();
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  register(registerData) {
    return new Promise((resolve, reject) => {
      HTTP.post("register", registerData)
        .then((response) => {
          if (response) return resolve(response.data);

          return resolve();
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  requestAdminAuthentication(data) {
    return new Promise((resolve, reject) => {
      HTTP.post("auth/request-admin-authentication", data)
        .then((response) => {
          if (response) return resolve(response.data);

          return resolve();
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};
